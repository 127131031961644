import React, { useEffect, useState } from "react"
import { useMediaQuery } from "react-responsive"
import { useHistory } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons"
import "./css/toplistpage.css"

const ToplistPage = () => {
  const [points, setPoints] = useState(0)
  const [topReaderOfWeek, settopReaderOfWeek] = useState([])
  const [topWeeklyBook, setTopWeeklyBook] = useState([])
  const [topList, setTopList] = useState([])
  const [topClassList, setTopClassList] = useState([])
  const [recBook, setRecBook] = useState([])
  const [weeklyBook, setWeeklyBook] = useState(null)
  const [filter, setFilter] = useState("points")
  const [order, setOrder] = useState("desc")
  const [classOptions, setClassOptions] = useState([])
  const [selectedClass, setSelectedClass] = useState("")
  const [classFilter, setClassFilter] = useState("points")
  const [classOrder, setClassOrder] = useState("desc")
  const [classParticipants, setClassParticipants] = useState([])

  const ITEMS_PER_PAGE = 35;
  const [currentPage, setCurrentPage] = useState(1);

  const big = useMediaQuery({ minWidth: 701 })
  const medium = useMediaQuery({ minWidth: 401 })

  useEffect(() => {
    let response = fetchData("/api/toplist?filter=points&order=desc")
    response.then(response => {
      settopReaderOfWeek(response[0])
      setTopList(response)
    })
    response = fetchData("/api/toplistClass")
    response.then(response => {
      setTopClassList(response)
    })
    response = fetchData('/api/getnumberofclassparticipants')
    response.then(response => {
      setClassParticipants(response)
    })
    response = fetchData("/api/mostreadbook")
    response.then(response => {
      setTopWeeklyBook(response[0])
    })
    response = fetchData("/api/userpoints")
    response.then(response => {
      setPoints(response[0].points)
    })
    response = fetchData("/api/classes")
    response.then(response => {
      setClassOptions(response)
    }).then(() => {
      setClassOptions(prevOptions => [...prevOptions, {id: 1, classname: "Lärare"}]);
    })
    fetchRec()
    fetchWeeklyPick()
  }, [])

  useEffect(() => {
    let response = fetchData("/api/toplist?filter=" + filter + "&order=" + order)
    response.then(response => {
      setTopList(response)
    })
  }, [filter, order])

  const handleClick = (newFilter, newOrder) => {
    if (newFilter == filter) {
      setOrder(order == "desc" ? "asc" : "desc")
    } else {
      setFilter(newFilter)
      setOrder(newOrder)
    }
  }

  const fetchWeeklyPick = () => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type" : "application/json" }
    }

    fetch("/api/getweeklypick", requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        return response.json()
      })
      .then(data => {
        setWeeklyBook(data)
      })
      .catch(error => {
        console.error("Error fetching weekly pick:", error)
      })
  }

  const fetchData = query => {
    let req = { method: "GET", headers: { "Content-Type": "application/json" } }
    return fetch(query, req).then(response => response.json())
  }

  const fetchRec = () => {
    fetch("/api/recommendation")
      .then(response => response.json())
      .then(response => {
        setRecBook(response[0])
      })
  }

  const [isStudentListVisible, setStudentListVisible] = useState(true)
  const [isClassListVisible, setClassListVisible] = useState(true)

  const toggleStudentList = () => {
    setStudentListVisible(!isStudentListVisible)
  }

  const toggleClassList = () => {
    setClassListVisible(!isClassListVisible)
  }

  const filteredList = topList.filter((listItem) => {
    return selectedClass === "" || listItem.classname === selectedClass;
  });
  const totalPages = Math.ceil(filteredList.length / ITEMS_PER_PAGE)
  const currentItems = filteredList.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );
  const emptyStudentRows = Array.from({ length: ITEMS_PER_PAGE - currentItems.length });
  const emptyClassRows = Array.from({ length: ITEMS_PER_PAGE - topClassList.length});

  const handlePageChange = (direction) => {
    setCurrentPage((prevPage) => {
      if(direction === "next" && prevPage < totalPages) {
        return prevPage + 1;
      } else if (direction === "prev" && prevPage > 1) {
        return prevPage - 1;
      }
      return prevPage;
    })
  }

  const handleSelectorEvent = (e) => {
    setSelectedClass(e.target.value);
  }

  const handleClassSelection = (classname) => {
    setSelectedClass(classname)
  }

  const handleClassClick = (filter, defaultOrder = "desc") => {
    if (filter !== classFilter) {
      setClassOrder(defaultOrder);  
    } else {
      setClassOrder(prevOrder => (prevOrder === "desc" ? "asc" : "desc"))
    }
    setClassFilter(filter);
  }

  const columnStyling = {
    name: { width: "40%"},
    classname: { width: "20%"},
    points: { width: "20%", textAlign: "center"},
    reading: { width: "20%", textAlign: "center"}
  }

  const participantsMap = new Map(classOptions.map(item => {
    const participantData = classParticipants.find(p => p.classid === item.id);
    return [item.classname, participantData ? participantData.participants : null]
  }))

  const history = useHistory()
  return (
    <div className='main-page-general-styling'>
      <div className='main-page-header glassMorphism'>
        <h2> Topplista </h2>
      </div>
      <div className='main-page-inner-container'>
        <div className='top-page-content'>

            <div className='top-top-reader-card glassMorphism'>
              <p className='top-card-title'> Veckans toppläsare </p>
              <hr />
              <p className='top-card-text'>{topReaderOfWeek ? topReaderOfWeek.name + ": " + topReaderOfWeek.points : null}</p>
            </div>

            <div className='top-top-book-card glassMorphism'>
              <p className='top-card-title'>Bokrekommendation</p>
              <hr />

              {topWeeklyBook ? (
                <div onClick={() => history.push("/books/" + topWeeklyBook.id)} className='recommended-wrapper'>
                  <div>
                    <div>
                      <strong>Titel</strong>
                      <div style={{ borderBottom: "1px solid black", height: "2px", width: "40px" }} />
                      <span className='underline'>{topWeeklyBook.title}</span>
                    </div>
                    <div>
                      <strong>Författare</strong>
                      <div style={{ borderBottom: "1px solid black", height: "2px", width: "70px" }} />
                      <span className='underline'>{topWeeklyBook?.author?.replace(/[\[\]"]/g, "")}</span>
                    </div>
                    <div>
                      <strong>Sidor</strong>
                      <div style={{ borderBottom: "1px solid black", height: "2px", width: "40px" }} />
                      <span className='underline'>{topWeeklyBook.pages}</span>
                    </div>
                  </div>
                  <div className='cbc-book-img'>
                    <img
                      className='img-fluid'
                      src={
                        topWeeklyBook.thumbnail
                          ? topWeeklyBook.thumbnail.thumbnail
                            ? topWeeklyBook.thumbnail.thumbnail
                            : topWeeklyBook.thumbnail
                          : "https://upload.wikimedia.org/wikipedia/commons/thumb/1/16/No_image_available_450_x_600.svg/450px-No_image_available_450_x_600.svg.png"
                      }
                      alt='Bok'
                    />
                  </div>
                </div>
              ) : (
                <div className='top-inner-recommendation'></div>
              )}
            </div>

            <div className='top-my-points glassMorphism'>
              <p className='top-card-title'> Mina poäng </p>
              <hr />
              <p className='top-card-text'> {points} </p>
            </div>

            <div className='top-my-recommendation glassMorphism'>
              <p className='top-card-title'>Veckans bok</p>
              <hr />

              {weeklyBook ? (
                <div className='recommended-wrapper' onClick={() => history.push("/books/" + weeklyBook.id)}>
                  <div>
                    <div>
                      <strong>Titel</strong>
                      <div style={{ borderBottom: "1px solid black", height: "2px", width: "40px" }} />
                      <span className='underline'>{weeklyBook.title}</span>
                    </div>
                    <div>
                      <strong>Författare</strong>
                      <div style={{ borderBottom: "1px solid black", height: "2px", width: "70px" }} />
                      <span className='underline'>{weeklyBook.author}</span>
                    </div>
                    <div>
                      <strong>Sidor</strong>
                      <div style={{ borderBottom: "1px solid black", height: "2px", width: "40px" }} />
                      <span className='underline'>{weeklyBook.pages}</span>
                    </div>
                  </div>
                  <div className='cbc-book-img'>
                    <img
                      className='img-fluid'
                      src={
                        weeklyBook.thumbnail
                          ? weeklyBook.thumbnail.thumbnail
                            ? weeklyBook.thumbnail.thumbnail
                            : weeklyBook.thumbnail
                          : "https://upload.wikimedia.org/wikipedia/commons/thumb/1/16/No_image_available_450_x_600.svg/450px-No_image_available_450_x_600.svg.png"
                      }
                      alt='Bok'
                    />
                  </div>
                </div>
              ) : (
                <div className='top-inner-recommendation'></div>
              )}
            </div>

              <div className="table-section top-top-student-list">
                {/* Student List Toggle Button */}
                <h2 onClick={toggleStudentList} style={{ cursor: "pointer" }}>
                  {isStudentListVisible ? <FontAwesomeIcon icon={faCaretDown} /> : <FontAwesomeIcon icon={faCaretUp} />} Topplista: Elev
                </h2>

                {/* Student List - Conditionally Rendered */}
                {isStudentListVisible && (
                  <>                
                    <select name="classname" itemType="text" value={selectedClass} onChange={handleSelectorEvent}>
                      <option value=''>Alla Klasser</option>
                      {classOptions.map(({id, classname}) => (
                        <option key={id} value={classname}>
                          {classname}
                        </option>
                      ))}
                    </select>

                    <table className='table table-holder'>
                      <thead>
                        <tr>
                          <th style={columnStyling.name} onClick={() => handleClick("name", "asc")} scope='col'>
                            {filter === "name" ? <FontAwesomeIcon icon={order === "asc" ? faCaretUp : faCaretDown} /> : ""} Elev
                          </th>
                          {medium && (
                            <th style={columnStyling.classname} onClick={() => handleClick("classname", "desc")} scope='col'>
                              {filter === "classname" ? <FontAwesomeIcon icon={order === "desc" ? faCaretUp : faCaretDown} /> : ""} Klass
                            </th>
                          )}
                          <th style={columnStyling.points} onClick={() => handleClick("points", "desc")} scope='col'>
                            {filter === "points" ? <FontAwesomeIcon icon={order === "desc" ? faCaretUp : faCaretDown} /> : ""} Poäng
                          </th>
                          {big && (
                            <th style={columnStyling.reading} onClick={() => handleClick("booksread", "desc")} scope='col'>
                              {filter === "booksread" ? <FontAwesomeIcon icon={order === "desc" ? faCaretUp : faCaretDown} /> : ""} Läst
                            </th>
                          )}
                        </tr>
                      </thead>

                      <tbody>
                        {currentItems.map((listItem) => (
                          <tr key={listItem.name}>
                            <td style={columnStyling.name} >{listItem.name}</td>
                            {medium && <td style={columnStyling.classname} >{listItem.classname}</td>}
                            <td style={columnStyling.points} >{listItem.points}</td>
                            {big && <td style={columnStyling.reading } >{listItem.booksread}</td>}
                          </tr>
                        ))}
                        {emptyStudentRows.map((_, index) => (
                          <tr key={`empty-${index}`}>
                            <td>&nbsp;</td>
                            {medium && <td>&nbsp;</td>}
                            <td>&nbsp;</td>
                            {big && <td>&nbsp;</td>}
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <div className="pagination">
                      <button
                        onClick={() => handlePageChange("prev")}
                        disabled={currentPage === 1}
                      >
                        &lt; Previous
                      </button>
                      <span>Sida {currentPage}</span>
                      <button
                        onClick={() => handlePageChange("next")}
                        disabled={currentPage === totalPages}
                      >
                        Next &gt;
                      </button>
                    </div>
                  </>
                )}
              </div>

              <div className="table-section top-top-class-list">
                {/* Class List Toggle Button */}
                <h2 onClick={toggleClassList} style={{ cursor: "pointer" }}>
                  {isClassListVisible ? <FontAwesomeIcon icon={faCaretDown} /> : <FontAwesomeIcon icon={faCaretUp} />} Topplista: Klass
                </h2>

                {/* Class List - Conditionally Rendered */}
                {isClassListVisible && (
                  <table className='table table-holder'>
                    <thead>
                      <tr>
                        {medium && (
                          <th style={{ width: "25%"}} onClick={() => handleClassClick("classname")} scope='col'>
                            {classFilter === "classname" ? <FontAwesomeIcon icon={classOrder === "desc" ? faCaretUp : faCaretDown} /> : ""} Klass
                          </th>
                        )}
                        <th style={{ width: "25%"}} onClick={() => handleClassClick("points")} scope='col'>
                          {classFilter === "points" ? <FontAwesomeIcon icon={classOrder === "desc" ? faCaretUp : faCaretDown} /> : ""} Poäng
                        </th>
                        {big && (
                          <th style={{ width: "25%"}} onClick={() => handleClassClick("participants")} scope='col'>
                            {classFilter === "participants" ? <FontAwesomeIcon icon={classOrder === "desc" ? faCaretUp : faCaretDown} /> : ""} Deltagare
                          </th>
                        )}
                        {big && (
                          <th style={{ width: "25%"}} onClick={() => handleClassClick("booksread")} scope='col'>
                            {classFilter === "booksread" ? <FontAwesomeIcon icon={classOrder === "desc" ? faCaretUp : faCaretDown} /> : ""} Läst
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {topClassList
                      .sort((a, b) => {
                        const isAscending = classOrder === "asc" ? 1 : -1;
                        if (classFilter === "classname") {
                          return a.classname.localeCompare(b.classname) * isAscending;
                        } else if (classFilter === "points") {
                          return (a.totalpoints - b.totalpoints) * isAscending;
                        } else if (classFilter === "participants") {
                          return (participantsMap.get(a.classname) - participantsMap.get(b.classname)) * isAscending;
                        } else if (classFilter === "booksread") {
                          return (a.totalbooksread - b.totalbooksread) * isAscending;
                        }
                        return 0;
                      })
                      .map(listItem => (
                        <tr key={listItem.classname}
                            onClick={() => handleClassSelection(listItem.classname)}>
                          {medium && (
                            <td

                              style={{
                                fontWeight: selectedClass === listItem.classname ? 'bold' : 'normal',
                                cursor: 'pointer',
                                width: "25%"
                              }}
                            >
                              {listItem.classname}
                            </td>
                          )}
                          <td style={{ width: "25%"}} >{listItem.totalpoints}</td>
                          {big && <td style={{ width: "25%"}} >{participantsMap.get(listItem.classname)}</td>}
                          {big && <td style={{ width: "25%"}} >{listItem.totalbooksread}</td>}
                        </tr>
                      ))}
                      {emptyClassRows.map((_, index) => (
                        <tr key={`empty-${index}`}>
                          <td>&nbsp;</td>
                          {medium && <td>&nbsp;</td>}
                          <td>&nbsp;</td>
                          {big && <td>&nbsp;</td>}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>              
        </div>
      </div>
    </div>
  )
}

export default ToplistPage
